import React, {Component} from "react"
import {Model} from "./Model"
import {Gate} from "./lib/gate/Gate"

// import classNames from "classnames";
import styles from "./Maintenance.module.css"

interface Props {}

export class Maintenance extends Component<Props, {}> {
    public render() {
        return (
            <div className={styles.bgimg}>
                <div className={styles.middle}>
                    <h1>Portal site changed please use</h1>
                    <p>
                        <a href="https://admissions-boa.edular.com" target="_blank">
                            {" "}
                            https://admissions-boa.edular.com
                        </a>
                    </p>
                </div>
                <div className={styles.bottomleft}>
                    <h4>Admissions Portal down</h4>
                </div>
            </div>
        )
    }
}
